import debounce from "lodash/debounce";
import { useCallback, useMemo } from "react";

import paginationActions from "actions/pagination";
import { selectSearchTerm } from "selectors/pagination";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";
import useWindowSize from "hooks/useWindowSize";

function useSearchTerm(props) {
  const { listKey, eventContext } = props;

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const { setSearchTerm } = useActionCreators(paginationActions);

  const term = useReduxState(
    (state) => selectSearchTerm(state, { key: listKey }),
    [listKey]
  );

  const onSearchTermRequest = useCallback(() => {
    sendGAEvent({
      action: "PodcastSearchClick",
      term,
      ...eventContext,
    });
  }, [term, eventContext]);

  const handleSearch = useMemo(
    () =>
      debounce(
        (value) => {
          const trimmedValue = value.trim();
          if (listKey) {
            setSearchTerm(listKey, trimmedValue, onSearchTermRequest);
          }
        },
        mobile ? 800 : 100
      ),
    [listKey, mobile, onSearchTermRequest, setSearchTerm]
  );

  const handleChange = useCallback(
    (value) => handleSearch(value),
    [handleSearch]
  );

  return { term, handleChange };
}

export default useSearchTerm;
