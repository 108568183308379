import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import deepmerge from "ramda/src/mergeDeepRight";
import { memo, useMemo } from "react";

import BadgeContainer from "components/Tastemaker/BadgeContainer";

import EntityImage from "../EntityImage";

import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import ScreenSizes from "styles/ScreenSizes";

const entityImageStyles = {
  entityImage: {
    // TODO: Maybe use EntityImage instead of redoing stuff here...
    ...cardStyles.mobileCompactCardEntityImage,
    background: "none",
    marginRight: 0,

    [ScreenSizes.lgAndAbove]: {
      ...cardStyles.desktopCardEntityImage,
      marginRight: 0,
      background: "none",
    },
  },
};

const compactEntityImageStyles = {
  entityImage: {
    ...entityImageStyles.entityImage,
    padding: 0,
    position: "relative",
    background: "none",

    [ScreenSizes.lgAndAbove]: {
      ...entityImageStyles.entityImage[ScreenSizes.lgAndAbove],
      background: "none",
    },
  },
};

const badgeContainerStyles = {
  badgeContainer: {
    left: "-0.25rem",
    bottom: "-0.25rem",
  },
  badge: {
    width: "1.875rem",
    height: "1.875rem",
  },
};

const EntityCardImage = (props) => {
  const {
    entity,
    entityType,
    compact,
    renderEntityImageOverlay,
    imgProps,
    entityImageProps,
    styles: passedStyles,
    mobile,
  } = props;

  const finalStyles = useMemo(() => {
    const defaultStyles = compact
      ? compactEntityImageStyles
      : entityImageStyles;

    if (passedStyles) {
      return deepmerge(defaultStyles, passedStyles);
    }

    return compact ? compactEntityImageStyles : entityImageStyles;
  }, [compact, passedStyles]);

  const { styles } = useStyles(finalStyles, props);

  const renderImage = () => {
    const image = (
      <EntityImage
        entity={entity}
        entity_type={entityType}
        size={mobile || compact ? 64 : 128}
        renderEntityImageOverlay={renderEntityImageOverlay}
        imgProps={imgProps}
        {...entityImageProps}
      />
    );

    if (entityType === "user") {
      return (
        <BadgeContainer
          styles={badgeContainerStyles}
          userId={entity?.get("id")?.toString()}
        >
          {image}
        </BadgeContainer>
      );
    }

    return image;
  };

  return <div className={css(styles.entityImage)}>{renderImage()}</div>;
};

EntityCardImage.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entityType: PropTypes.string,
  compact: PropTypes.bool,
  renderEntityImageOverlay: PropTypes.func,
  imgProps: PropTypes.object,
  entityImageProps: PropTypes.object,
  renderOnMount: PropTypes.bool,
  styles: PropTypes.object,
  mobile: PropTypes.bool,
};

EntityCardImage.defaultProps = {
  entity: Map(),
  compact: false,
  renderEntityImageOverlay: null,
  imgProps: {},
  entityImageProps: {},
  renderOnMount: false,
  styles: null,
  mobile: false,
};

export default memo(EntityCardImage);
